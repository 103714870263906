.scrollbarContainer {
  display: none;
}

.draggableElement {
  background: var(--color-text-primary);
  height: 100%;
  width: 100%;
  position: relative;
  border-radius: var(--swiper-scrollbar-border-radius, 10px);
  left: 0;
  top: 0;
}

.hideFromDesktop {
  display: none;
  @media screen and (max-width: 1023px) {
    display: block;
  }
}

.root :global(.swiper-slide) {
  @media screen and (--desktop) {
    width: 403.6px;
  }
  @media screen and (--desktop-xs) {
    width: 353.55px;
  }
  @media screen and (--desktop-l) {
    width: 352.5px;
  }
  @media screen and (--desktop-xl) {
    width: 380px;
  }
  @media screen and (--desktop-xxl) {
    width: 418.333px;
  }
}
