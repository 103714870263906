.card {
  width: 383px;
}

.image {
  height: 140px;
  min-width: 140px;
}

.roundedCorner {
  border-radius: 15px;
  padding: 10px;
}

@media screen and (--mobile) {
  .card {
    width: 327px;
  }

  .image {
    height: 124px;
    min-width: 124px;
  }
}

@media screen and (--tablet-s) {
  .title {
    --typography-body-regular-font-size: var(--typography-body-large-font-size);
    --typography-body-regular-line-height: var(
      --typography-body-large-line-height
    );
  }
}
